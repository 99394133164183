.t-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: none;
  transform: none
}

.t-loader--blocked {
  padding: 20px 0 65px;
  position: static;
  -webkit-transform: none;
  transform: none
}

.t-loader--blocked .t-loader,
.t-loader--blocked .t-loader__spinner-container {
  position: relative;
  top: auto;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0)
}

.t-loader[data-t-loader-show=false] {
  opacity: 0
}

.t-loader__spinner-container, .round-spinner_new {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px
}

@supports (-ms-ime-align:auto) {
  .t-loader__spinner-container {
    width: 48px;
    height: 48px;
    background-image: url("./spinner.png");
    background-size: 2160px 48px;
    background-position: 0 0;
    -webkit-animation: ie-spin 2s steps(45) infinite;
    animation: ie-spin 2s steps(45) infinite
  }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .t-loader__spinner-container {
    width: 48px;
    height: 48px;
    background-image: url("./spinner.png");
    background-size: 2160px 48px;
    background-position: 0 0;
    -webkit-animation: ie-spin 2s steps(45) infinite;
    animation: ie-spin 2s steps(45) infinite
  }
}

.t-loader__spinner1,
.t-loader__spinner2,
.t-loader__spinner3 {
  position: absolute;
  top: 0;
  left: 0
}

@supports (-ms-ime-align:auto) {

  .t-loader__spinner1,
  .t-loader__spinner2,
  .t-loader__spinner3 {
    display: none
  }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {

  .t-loader__spinner1,
  .t-loader__spinner2,
  .t-loader__spinner3 {
    display: none
  }
}

.t-loader__spinner1 .path,
.t-loader__spinner2 .path,
.t-loader__spinner3 .path {
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  stroke-width: 8;
  stroke-linecap: round;
  -webkit-transform-origin: center;
  transform-origin: center
}

.t-loader__spinner1 {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite
}

.t-loader__spinner1 .path {
  -webkit-animation: dash 2s ease-in-out infinite;
  animation: dash 2s ease-in-out infinite;
  stroke: #1a5199
}

.t-loader__spinner2 {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite
}

.t-loader__spinner2 .path {
  -webkit-animation: dash2 2s ease-in-out infinite;
  animation: dash2 2s ease-in-out infinite;
  stroke: #0c6de4
}

.t-loader__spinner3 {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite
}

.t-loader__spinner3 .path {
  -webkit-animation: dash 2s ease-in-out infinite, dash3 2s ease-in-out infinite;
  animation: dash 2s ease-in-out infinite, dash3 2s ease-in-out infinite;
  stroke: #1a5199
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }

  to {
    -webkit-transform: rotate(630deg);
    transform: rotate(630deg)
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }

  to {
    -webkit-transform: rotate(630deg);
    transform: rotate(630deg)
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 200
  }

  20% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(125deg);
    transform: rotate(125deg)
  }

  50% {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }

  85% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(475deg);
    transform: rotate(475deg)
  }

  to {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn)
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 200
  }

  20% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(125deg);
    transform: rotate(125deg)
  }

  50% {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }

  85% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(475deg);
    transform: rotate(475deg)
  }

  to {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn)
  }
}

@-webkit-keyframes dash2 {
  0% {
    stroke-dashoffset: 200
  }

  35% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(125deg);
    transform: rotate(125deg)
  }

  50% {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }

  70% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(475deg);
    transform: rotate(475deg)
  }

  to {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn)
  }
}

@keyframes dash2 {
  0% {
    stroke-dashoffset: 200
  }

  35% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(125deg);
    transform: rotate(125deg)
  }

  50% {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }

  70% {
    stroke-dashoffset: 71.42857;
    -webkit-transform: rotate(475deg);
    transform: rotate(475deg)
  }

  to {
    stroke-dashoffset: 200;
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn)
  }
}

@-webkit-keyframes dash3 {
  0% {
    opacity: 0
  }

  49% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  99% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes dash3 {
  0% {
    opacity: 0
  }

  49% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  99% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@-webkit-keyframes ie-spin {
  to {
    background-position: -2160px
  }
}

@keyframes ie-spin {
  to {
    background-position: -2160px
  }
}

.t-animation {
  --animate-in-speed: 0.6s;
  --animate-out-speed: 0.4s
}

.t-animation .t-paper>:first-child {
  --shift-start-distance: 50px;
  --shift-end-distance: 0;
  opacity: 0;
  -webkit-transform: translateY(var(--shift-in-distance));
  transform: translateY(var(--shift-in-distance));
  will-change: opacity, transform;
  -webkit-animation: fade-in var(--animate-in-speed) .25s 1 forwards, shift-in var(--animate-in-speed) .25s 1 forwards;
  animation: fade-in var(--animate-in-speed) .25s 1 forwards, shift-in var(--animate-in-speed) .25s 1 forwards
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .t-animation .t-paper>:first-child {
    -webkit-animation: none;
    animation: none;
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.t-animation .t-paper>:nth-child(2) {
  --shift-start-distance: 50px;
  --shift-end-distance: 0;
  opacity: 0;
  -webkit-transform: translateY(var(--shift-in-distance));
  transform: translateY(var(--shift-in-distance));
  will-change: opacity, transform;
  -webkit-animation: fade-in var(--animate-in-speed) .5s 1 forwards, shift-in var(--animate-in-speed) .5s 1 forwards;
  animation: fade-in var(--animate-in-speed) .5s 1 forwards, shift-in var(--animate-in-speed) .5s 1 forwards
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .t-animation .t-paper>:nth-child(2) {
    -webkit-animation: none;
    animation: none;
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.t-animation[data-t-animation-close] .t-paper>:first-child {
  --shift-end-distance: -200px;
  --shift-start-distance: 0;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: opacity, transform;
  -webkit-animation: fade-out var(--animate-out-speed) .125s 1 forwards, shift-out var(--animate-out-speed) .125s 1 forwards;
  animation: fade-out var(--animate-out-speed) .125s 1 forwards, shift-out var(--animate-out-speed) .125s 1 forwards
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .t-animation[data-t-animation-close] .t-paper>:first-child {
    -webkit-animation: none;
    animation: none;
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.t-animation[data-t-animation-close] .t-paper>:nth-child(2) {
  --shift-end-distance: -200px;
  --shift-start-distance: 0;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: opacity, transform;
  -webkit-animation: fade-out var(--animate-out-speed) .25s 1 forwards, shift-out var(--animate-out-speed) .25s 1 forwards;
  animation: fade-out var(--animate-out-speed) .25s 1 forwards, shift-out var(--animate-out-speed) .25s 1 forwards
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .t-animation[data-t-animation-close] .t-paper>:nth-child(2) {
    -webkit-animation: none;
    animation: none;
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fade-in {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes fade-out {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@-webkit-keyframes shift-in {
  0% {
    -webkit-transform: translateY(var(--shift-start-distance));
    transform: translateY(var(--shift-start-distance))
  }

  to {
    -webkit-transform: translateY(var(--shift-end-distance));
    transform: translateY(var(--shift-end-distance))
  }
}

@keyframes shift-in {
  0% {
    -webkit-transform: translateY(var(--shift-start-distance));
    transform: translateY(var(--shift-start-distance))
  }

  to {
    -webkit-transform: translateY(var(--shift-end-distance));
    transform: translateY(var(--shift-end-distance))
  }
}

@-webkit-keyframes shift-out {
  0% {
    -webkit-transform: translateY(var(--shift-start-distance));
    transform: translateY(var(--shift-start-distance))
  }

  to {
    -webkit-transform: translateY(var(--shift-end-distance));
    transform: translateY(var(--shift-end-distance))
  }
}

@keyframes shift-out {
  0% {
    -webkit-transform: translateY(var(--shift-start-distance));
    transform: translateY(var(--shift-start-distance))
  }

  to {
    -webkit-transform: translateY(var(--shift-end-distance));
    transform: translateY(var(--shift-end-distance))
  }
}

.cnsb_container {
  visibility: hidden
}

.container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  visibility: hidden
}

.t-boostloader__spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.t-circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 48px;
  height: 49px;
  margin: auto
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}