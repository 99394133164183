.cid-idp-selector-button {
  min-width: 311px;
  border-radius: 12px;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* font-family: var(--font); */
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #262626;
  background-color: #262626;
  color: white;
}

.cid-idp-selector-button svg {
  background: transparent;
  fill: white;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.cid-idp-selector-button--large {
  padding: 15px 26px;
  font-size: 16px;
}

.cid-idp-selector-button--dark {
  border: 1px solid #262626;
  background-color: #262626;
  color: white;
}

.cid-idp-selector-button--light {
  border: 1px solid white;
  background-color: white;
  color: #262626;
}

.cid-idp-selector-button-icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.cid-idp-selector-button--large .cid-idp-selector-button-icon {
  width: 24px;
  height: 24px;
}

.cid-idp-selector-button--dark .cid-idp-selector-button-icon {
  fill: white;
}

.cid-idp-selector-button--light .cid-idp-selector-button-icon {
  fill: #262626;
}

@media (max-width: 488px) {
  .cid-idp-selector-button {
    width: 100%;
    min-width: auto;
  }
}