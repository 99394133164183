@keyframes animation {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}
.cid-idp-selector-skeleton {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.cid-idp-selector-skeleton:after {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, white 50%, transparent 100%);
  animation-name: animation;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: infinite;
}
