:root {
  --font: 'Telstra Akkurat, Helvetica Neue, Helvetica, Arial', sans-serif;
  --charcoal: #262626;
}

.claimsList svg {
  margin-right: 10px;
}

.claimsList p, .able-text-style--TextBodyShort>p+p {
  width: 50%;
  display: inline-block;
  margin: 5px 0;
}

.page-container {
  max-width: 360px;
}

.serversGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px
}

.infobox {
  border-radius: .25rem;
  border-left-width: .25rem;
  border-left-style: solid;
  border-left-color: #0064d2;
  background-color: rgba(0, 100, 210, 0.06);
  padding: 15px
}