@use '..' as able;

.able-NavigationTile {
  @include able.NavigationTile();

  &_label {
    @include able.TextStyle(FinePrint, $alignment: Center);
    display: block;
    padding-top: 20px;
  }

  &--ComfortableGap {
    @include able.NavigationTile($gutter: Comfortable);
  }

  &--CompactGap {
    @include able.NavigationTile($gutter: Compact);
  }

  &--Centred {
    @include able.NavigationTile($centred: true);

    &--ComfortableGap {
      @include able.NavigationTile($gutter: Comfortable, $centred: true);
    }

    &--CompactGap {
      @include able.NavigationTile($gutter: Compact, $centred: true);
    }
  }

  &--Custom {
    @include able.NavigationTile(
      (
        xs: 2,
        sm: 2,
        md: 4,
        lg: 4,
      )
    );

    &--ComfortableGap {
      @include able.NavigationTile(
        (
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
        ),
        $gutter: Comfortable
      );
    }

    &--CompactGap {
      @include able.NavigationTile(
        (
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
        ),
        $gutter: Compact
      );
    }

    &--Centred {
      @include able.NavigationTile(
        (
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
        ),
        $centred: true
      );

      &--ComfortableGap {
        @include able.NavigationTile(
          (
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
          ),
          $gutter: Comfortable,
          $centred: true
        );
      }

      &--CompactGap {
        @include able.NavigationTile(
          (
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
          ),
          $gutter: Compact,
          $centred: true
        );
      }
    }
  }
}

.eg--tiles {
  width: auto;
  margin-left: able.spacing('spacing2x');
  margin-right: able.spacing('spacing2x');
}
